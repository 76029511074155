<template>
  <layout :menu-tree="menuTree"></layout>
</template>

<script>
import Layout from "@/views/front/jsf35/operations";
import {reactive, toRefs} from "@vue/composition-api";

export default {
  name: "EngineeringCenter",
  components: {
    Layout,
  },
  setup() {
    const event = reactive({
      menuTree: [
        {
          title: "综合概览",
          iframe: "app.dreamsfount35.com/ems/index/index",
        },
        {
          title: "综合调度管理",
          children: [
            {
              title: "物业排班管理",
              children: [
                {
                  title: "人员排班记录",
                  iframe: "app.dreamsfount35.com/ems/scheduling/index",
                },
              ],
            },
            {
              title: "人员考勤管理",
              children: [
                {
                  title: "签到记录",
                  iframe: "app.dreamsfount35.com/ems/signIn/index",
                },
                {
                  title: "签到设置",
                  iframe: "app.dreamsfount35.com/ems/signIn/setting",
                },
              ],
            },
            {
              title: "投诉管理",
              children: [
                {
                  title: "投诉列表",
                  iframe: "app.dreamsfount35.com/ems/complaint/index",
                },
              ],
            },
          ],
        },
        {
          title: "多端报修管理",
          children: [
            {
              title: "报修报表",
              component_key: "RepairList",
            },
            {
              title: "网页报修",
              iframe: "v4.qiyebox.com/#/m/property/repair",
            },
            {
              title: "报修列表",
              iframe: "app.dreamsfount35.com/ems/repairRequest/index",
            },
            {
              title: "待办消息",
              children: [
                {
                  title: "待办消息列表",
                  iframe: "app.dreamsfount35.com/ems/message/index",
                },
                {
                  title: "待办消息分类",
                  iframe: "app.dreamsfount35.com/ems/messageType/index",
                },
              ],
            },
            {
              title: "物业工单",
              children: [
                /* {
                    title: "工单管理",
                    iframe: "v4.qiyebox.com/#/m/property/property/list",
                }, */

                {
                  title: "巡检记录",
                  iframe: "v4.qiyebox.com/#/m/property/patrol_scheme/record",
                },
                {
                  title: "巡检计划",
                  iframe: "v4.qiyebox.com/#/m/property/patrolScheme/list",
                },
                {
                  title: "物业KPI",
                  iframe: "v4.qiyebox.com/#/m/property/kpi",
                },
                {
                  title: "装修管理",
                  iframe: "v4.qiyebox.com/#/m/property/renovation/list",
                },
              ],
            },
          ],
        },
        {
          title: "物品管理",
          children: [
            {
              title: "物品放行",
              iframe: "v4.qiyebox.com/#/m/property/pass/list",
            },
            {
              title: "物品借用",
              iframe: "v4.qiyebox.com/#/m/property/release/list",
            },
          ],
        },
        {
          title: "仓储物料管理",
          children: [
            {
              title: "仓库管理",
              children: [
                {
                  title: "仓库列表",
                  iframe: "app.dreamsfount35.com/ems/store/index",
                },
                {
                  title: "入库列表",
                  iframe: "app.dreamsfount35.com/ems/sparePartStockLog/enter",
                },
                {
                  title: "出库列表",
                  iframe: "app.dreamsfount35.com/ems/sparePartStockLog/outer",
                },
                {
                  title: "库存列表",
                  iframe: "app.dreamsfount35.com/ems/stock/index",
                },
                {
                  title: "库存盘点",
                  iframe: "app.dreamsfount35.com/ems/sparePartStockLog/inventoryCheck",
                },
              ],
            },
            {
              title: "物料领用管理",
              children: [
                {
                  title: "领用单列表",
                  iframe: "app.dreamsfount35.com/ems/sparePartRequisition/index",
                },
              ],
            },

            /* {
                title: "物品放行",
                children: [
                    {
                        title: "物品放行申请列表",
                        iframe: "app.dreamsfount35.com/ems/release/index",
                    },
                ],
            }, */

            {
              title: "备件列表",
              iframe: "app.dreamsfount35.com/ems/sparePart/index",
            },
            {
              title: "空间列表",
              iframe: "app.dreamsfount35.com/ems/space/index",
            },
          ],
        },
        {
          title: "供应商管理",
          children: [
            {
              title: "供应商列表",
              iframe: "app.dreamsfount35.com/ems/supplier/index",
            },
            {
              title: "供应商类型",
              iframe: "app.dreamsfount35.com/ems/supplierCategory/index",
            },
            {
              title: "合同列表",
              iframe: "app.dreamsfount35.com/ems/contract/index",
            },
          ],
        },
        {
          title: "资料管理",
          children: [
            /* {
                title: "报表管理",
                iframe: "",
            }, */
            {
              title: "资料列表",
              iframe: "app.dreamsfount35.com/ems/material/index",
            },
          ],
        },
        {
          title: "停车管理",
          children: [
            {
              title: "车场设置",
              iframe: "v4.qiyebox.com/#/m/parking/list",
            },
            {
              title: "停车记录",
              iframe: "v4.qiyebox.com/#/m/parking/record",
            },
            {
              title: "车位管理",
              iframe: "v4.qiyebox.com/#/m/parking/space",
            },
            {
              title: "优惠券管理",
              iframe: "v4.qiyebox.com/#/m/parking/coupon",
            },
          ],
        },
        {
          title: "访客管理",
          children: [
            {
              title: "访客记录",
              iframe: "v4.qiyebox.com/#/m/visitor/record",
            },
            {
              title: "社区注册用户",
              iframe: "v4.qiyebox.com/#/m/visitor/community",
            },
          ],
        },
        {
          title: "会议室管理",
          children: [
            {
              title: "会议室列表",
              //iframe: "app.dreamsfount35.com/ems/meetingRoom/index",
              iframe: "v4.qiyebox.com/#/m/meeting/list",
            },
            {
              title: "会议室预定",
              //iframe: "app.dreamsfount35.com/ems/meetingRoomReservation/index",
              iframe: "v4.qiyebox.com/#/m/meeting/order",
            },
            {
              title: "场地排期",
              iframe: "v4.qiyebox.com/#/m/meeting/place_list",
            },
            {
              title: "免费时长赠送记录",
              iframe: "v4.qiyebox.com/#/m/meeting/free_time_list",
            },
          ],
        },
      ],
    });

    return {
      ...toRefs(event),
    };
  },
};
</script>

<style lang="scss" scoped></style>
